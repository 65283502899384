import moment from "moment";
import { useRoot } from "./rootContext";

export const DateFormat = "YYYY-MM-DD";
export const UserDateTimeFormat = "YYYY-MM-DD HH:mm:ss";

export const toUtc = (date, addDay) => {
  if (date) {
    const utc = moment(date).add(new Date().getTimezoneOffset(), "minutes");
    if (addDay) return moment(utc).add(1, "days").format("YYYY-MM-DDTHH:mm:ss");
    return utc.format("YYYY-MM-DDTHH:mm:ss");
  }
};

export const toLocalDateTime = (isoTime) => {
  if (isoTime) {
    return moment.utc(isoTime).local().format(UserDateTimeFormat);
  }
};

export const fetchData = (url, options) => {
  const { blocker, root } = options || {};
  blocker && blocker.block();
  const token = localStorage.getItem("AppAuthToken") || "";
  return new Promise((resolve, reject) => {
    fetch(url, { headers: { Authorization: "bearer " + token } })
      .then(function (resp) {
        if (resp.ok) {
          resp.json().then(function (data) {
            resolve(data);
          });
        } else if (resp.status === 400 || resp.status === 500) {
          resp.json().then(function (data) {
            showError(data.message);
          });
        } else if (resp.status === 401) {
          root && root.setLogedIn(false);
          !root && console.warn("root context must be provided");
        }
      })
      .catch((e) => {
        reject(e);
        console.log(e);
        showError(
          "Something went wrong please contact the system administrator."
        );
      })
      .finally(() => {
        blocker && blocker.unblock();
      });
  });
};

export const postData = (url, data, options) => {
  const { blocker, root } = options || {};
  blocker && blocker.block();
  const token = localStorage.getItem("AppAuthToken") || "";
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    })
      .then(function (resp) {
        if (resp.ok) {
          resp.json().then(function (data) {
            resolve(data);
          });
        } else if (resp.status === 400 || resp.status === 500) {
          resp.json().then(function (data) {
            showError(data.message);
          });
        } else if (resp.status === 401) {
          root && root.setLogedIn(false);
          !root && console.warn("root context must be provided");
        }
      })
      .catch((e) => {
        reject(e);
        console.log(e);
        showError(
          "Something went wrong please contact the system administrator."
        );
      })
      .finally(() => {
        blocker && blocker.unblock();
      });
  });
};

export const showError = (msg) => {
  alert(msg);
};

export const showConfirm = (msg, onOk) => {
  if (window.confirm(msg)) {
    onOk && onOk();
  }
};
export const removeByIndex = (str, index) => {
  return str.slice(0, index) + str.slice(index + 1);
};

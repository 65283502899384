import {
  DollarOutlined,
  FileSyncOutlined,
  PlusOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Row, Checkbox, Form, Input } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  useAuth,
  useBlockScreen,
  useRoot,
  useSetActiveTab,
} from "../../rootContext";
import { postData } from "../../utils";

const Login = () => {
  const rootContext = useRoot();
  const setActiveTab = useSetActiveTab(rootContext);
  const navigate = useNavigate();
  const authHandler = useAuth();

  const blocker = useBlockScreen();

  useEffect(() => {
    setActiveTab("");
  }, []);
  const onFinish = (values) => {
    postData(window.SiteBasePath + "/api/auth/login", values, {
      blocker,
    }).then((resp) => {
      localStorage.setItem("AppAuthToken", resp.token);
      localStorage.setItem("LoginData", JSON.stringify(resp));
      authHandler.login(resp);
      //navigate("");
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Row>
        <Col span={8} offset={8}>
          <Card style={{ marginTop: "10px" }}>
            <h2
              style={{
                textAlign: "center",
                padding: "12px 0",
                color: "rgba(255, 255, 255, 0.65)",
                backgroundColor: "#001529",
                borderRadius: "4px",
              }}
            >
              User Login
            </h2>
            <Form
              style={{ marginTop: "25px" }}
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Username"
                name="Username"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  //   offset: 8,
                  span: 24,
                }}
                style={{ textAlign: "right" }}
              >
                <Button type="primary" htmlType="submit">
                  Login
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default Login;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchData, postData, showConfirm } from "../../utils";
import { Button, Card, Col, Row, Checkbox } from "antd";
import {
    DollarOutlined,
    FileSyncOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import { useBlockScreen, useRoot, useSetActiveTab } from "../../rootContext";

const Home = () => {
    const blocker = useBlockScreen();
    const root = useRoot();
    const setActiveTab = useSetActiveTab(root);
    const navigate = useNavigate();
    const [checkboxes, setCheckboxes] = useState([]);

    const handleCheckboxChange = (id, status) => {
        showConfirm("Are you sure?", () => {
            postData(
                window.SiteBasePath + "/api/supplier/set-status",
                { supplierId: id, supplierStatus: status },
                { blocker, root }
            ).then((resp) => onSupplierStatusUpdated(resp, id));
        });
    };

    const onSupplierStatusUpdated = (resp, supplierId) => {
        setCheckboxes((prevCheckboxes) =>
            prevCheckboxes.map((checkbox) =>
                checkbox.SupplierId === supplierId
                    ? { ...checkbox, Enabled: !checkbox.Enabled }
                    : checkbox
            )
        );
        resp.Message && alert(resp.Message);
    };

    useEffect(() => {
        fetchData(window.SiteBasePath + "/api/supplier/all", { blocker, root })
            .then((resp) => {
                console.log("API Response:", resp);
                if (resp && resp.length > 0) {
                    setCheckboxes(resp);
                } else {
                    console.error("Invalid response format:", resp);
                    // Handle invalid response format
                }
            })
            .catch((error) => {
                console.error("Error fetching suppliers:", error);
                // Handle fetch error
            });
        setActiveTab("");
    }, []);

    return (
        <>
            <h4 style={{ margin: "10px 0px" }}>Dashboard US Portal</h4>
            <Card style={{ marginTop: "10px" }}>
                <Row>
                    <Col span={4}>
                        <Button
                            type="green"
                            icon={<DollarOutlined />}
                            size="large"
                            style={{ width: "90%" }}
                            onClick={() => {
                                navigate("pricing");
                            }}
                        >
                            Pricing
                        </Button>
                    </Col>
                    <Col span={4}>
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            size="large"
                            style={{ width: "90%" }}
                            onClick={() => {
                                navigate("products/add");
                            }}
                        >
                            Add Product
                        </Button>
                    </Col>
                    <Col span={4}>
                        <Button
                            type="orange"
                            icon={<FileSyncOutlined />}
                            size="large"
                            style={{ width: "90%" }}
                            onClick={() => {
                                fetchData(
                                    window.SiteBasePath +
                                    "/api/pna/pull?pageCount=50&refreshAll=true",
                                    { blocker, root }
                                ).then((resp) => {
                                    resp.Message && alert(resp.Message);
                                });
                            }}
                        >
                            Sync Pricing+Qty
                        </Button>
                    </Col>
                </Row>
            </Card>
            <h4 style={{ margin: "10px 0px" }}>Enable / Disable Suppliers</h4>
            <Card>
                <Row>
                    {checkboxes.map((checkbox) => (
                        <Checkbox
                            key={checkbox.SupplierId}
                            checked={checkbox.Enabled}
                            onChange={(e) =>
                                handleCheckboxChange(
                                    checkbox.SupplierId,
                                    e.target.checked
                                )
                            }
                        >
                            {checkbox.SupplierName}
                        </Checkbox>
                    ))}
                </Row>
            </Card>
        </>
    );
};

export default Home;
